.site-layout-header {
}

.site-layout-header .logo {
    width: 120px;
    height: 31px;
    margin: 16px 28px 16px 0;
    float: left;
}


.site-layout-background {
    background: #F5F7FA;
}

.public-layout-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    text-align: center;
    vertical-align: middle;
}
