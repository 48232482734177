/** Input 仅展示效果 */
.input-display {
    border: 0;
    background-color: white;
    color: black;
}

/** 文本 Button */
.btn-text {
    color: #2680D8;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
}

/** 蓝底的下拉框 */
.card-space .ant-select-selection-placeholder {
  color: #fff;
  opacity: 1;
}

.card-space .ant-select-selector {
  color: #fff;
  background-color: #3c95f4 !important;
}

.card-space .ant-select-arrow {
  color: #fff;
}