.modal-open.modal-open .ant-modal-body {
  margin: 0;
  padding: 0;
}

.modal-open.modal-open .ant-modal-close {
  display: inline-block;
}

.modal-open.modal-open .ant-modal-confirm-content {
  margin: 0;
  padding: 0;
}

.modal-open.modal-open .ant-modal-confirm-btns {
  display: none;
}
